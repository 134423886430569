import React from 'react'
import "../Utils/Styles/404.css"

const  Pagenotfound = () =>{
  return (
    <div className=' container-fluid notfound'>
    <div className=' row'>
      <div className="col-md-12  Pagenotfound">
        
      </div>
    </div>
    </div>
  )
}

export default Pagenotfound